import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Dechante Chang</h2>
        <p><a href="mailto:dechantechang@hotmail.com">dechantechang@hotmail.com</a></p>
      </header>
    </section>
    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Dechante Chang. I am a to be computer science graduate from <a href="https://UCF.edu/">University Of Central Florida</a>, with experience as a front-end web developer for Socrates Digital Marketing Agency as well as a freelancer. I have also served as a Front End Web Developer for a company based in Orlando, FL, where I used computer systems and software to design and develop user-friendly websites by working closely with clients to understand their vision and using languages such as HTML, CSS, and JavaScript to bring that vision to life. I have proficiency in a range of programming languages and tools, including JavaScript, HTML, CSS, AWS, and Git.</p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Dechante Chang <Link to="/">dchang.xyz</Link>.</p>
    </section>
  </section>
);

export default SideBar;
